import { css } from 'styled-components';
import { focusRing } from '../../utils/mixins';
import { adjustLuminance } from '../../utils/adjustLuminance';
import { BUTTON_PADDINGS, BUTTON_FONT_SIZES } from '../constants/ButtonSizes';
const hoverStyle = css(["", ""], ({
  theme,
  use
}) => `background-color: ${adjustLuminance(theme.primary, use === 'primary' ? 20 : 95)};`);
const activeStyle = css(["", ""], ({
  theme,
  use
}) => `background-color: ${adjustLuminance(theme.primary, use === 'primary' ? -10 : 90)};`);
export const buttonTheme = {
  baseStyle: css(["padding:", ";font-size:", ";flex-shrink:0;border-radius:3px;line-height:16px;outline:none;transition:background-color 150ms ease-out;border-style:solid;border-width:1px;cursor:pointer;text-align:center;word-break:normal;overflow-wrap:break-word;background-color:transparent;", " ", " ", ""], ({
    size
  }) => BUTTON_PADDINGS[size], ({
    size
  }) => BUTTON_FONT_SIZES[size], ({
    theme,
    use
  }) => use === 'primary' ? `
          background-color: ${theme.primary};
          border: none;
          color: ${theme.textOnPrimary};
        ` : `
          background-color: transparent;
          border-color: ${theme.primary};
          color: ${theme.primary};
        `, ({
    hover
  }) => hover ? hoverStyle : '', ({
    active
  }) => active ? activeStyle : ''),
  _disabled: css(["background-color:", ";border:none;color:", ";cursor:not-allowed;user-select:none;"], ({
    theme
  }) => theme.disabledBackground, ({
    theme
  }) => theme.disabledText),
  _focused: focusRing,
  _hovered: hoverStyle,
  _pressed: activeStyle
};