import { colors as defaultColors, components as defaultComponents } from './defaultTheme';
import { mergeDeep } from '../utils/mergeDeep';
import { css } from 'styled-components';
export const wrapWithSelector = (selector, style) => css(["", "{", "}"], selector, style);
export function getComponentStyles({
  baseStyle,
  _disabled,
  _focused,
  _hovered,
  _pressed
}) {
  return css(["", " ", " ", " ", " ", ""], baseStyle, _disabled && wrapWithSelector('&:disabled', _disabled), _focused && wrapWithSelector('&:focus-visible', _focused), _hovered && wrapWithSelector('&:hover:enabled', _hovered), _pressed && wrapWithSelector('&:active:enabled', _pressed));
}
export const computeComponentStyles = components => Object.entries(components || {}).reduce((acc, [component, styleProps]) => {
  return Object.assign({}, acc, {
    [component]: {
      style: css(["", ""], getComponentStyles(styleProps))
    }
  });
}, {});
/**
 * Creates the theme object to customize the components based on the passed overrides.
 *
 * @param overrides A theme configuration object to merge/override the default values.
 * @returns The theme object used internally by the component library.
 */

export const createThemeV2 = (overrides = {}) => {
  const mergedColors = overrides.colors ? mergeDeep(defaultColors, overrides.colors) : defaultColors;
  return Object.assign({}, mergedColors, {
    colors: mergedColors,
    components: computeComponentStyles(overrides.components ? mergeDeep(defaultComponents, overrides.components) : defaultComponents)
  });
};