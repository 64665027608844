import { css } from 'styled-components';
import { focusRing } from '../../utils/mixins';
import { adjustLuminance } from '../../utils/adjustLuminance';
import { ON_BRIGHT, ERROR } from '../constants/LinkVariations';

const getLinkColor = ({
  use,
  theme
}) => use === ON_BRIGHT ? theme.text : use === ERROR ? theme.errorText : theme.linkText || theme.primary;

export const hoverStyle = css(["color:", ";text-decoration:underline;"], ({
  use,
  theme
}) => adjustLuminance(getLinkColor({
  use,
  theme
}), -30));
export const activeStyle = css(["color:", ";"], ({
  use,
  theme
}) => adjustLuminance(getLinkColor({
  use,
  theme
}), 30));
export const linkTheme = {
  baseStyle: css(["cursor:pointer;text-decoration:none;transition:all.15s ease-out;font-weight:400;color:", ";", " ", " ", " ", ""], getLinkColor, ({
    active
  }) => active && activeStyle, ({
    hover
  }) => hover && hoverStyle, ({
    use
  }) => use === ON_BRIGHT && `text-decoration: underline;`, ({
    use
  }) => use === ERROR && `font-weight: bold;`),
  _hovered: hoverStyle,
  _focused: focusRing,
  _pressed: activeStyle
};
export const globalLinkStyles = css(["", " &:hover:enabled{color:unset;text-decoration:none;}"], ({
  theme
}) => theme.components.Link.style);