import { EXTRA_SMALL, MEDIUM, SMALL } from '../../constants/sizes';
export const BUTTON_SIZES = {
  [EXTRA_SMALL]: 18,
  [SMALL]: 28,
  [MEDIUM]: 40
};
export const BUTTON_PADDINGS = {
  [EXTRA_SMALL]: '4px 8px',
  [SMALL]: '8px 16px',
  [MEDIUM]: '11px 24px'
};
export const BUTTON_FONT_SIZES = {
  [EXTRA_SMALL]: '10px',
  [SMALL]: '12px',
  [MEDIUM]: '14px'
};