import { css } from 'styled-components';
import { focusRing } from '../../utils/mixins';
import { CIRCLE } from '../constants/IconButtonShapes';
import { BUTTON_SIZES } from '../constants/ButtonSizes';
import { adjustLuminance } from '../../utils/adjustLuminance';
import { hexToRGB } from '../../utils/hexToRGB';
import { TRANSPARENT_ON_PRIMARY, TRANSPARENT_ON_BACKGROUND } from '../constants/IconButtonUses';

const hexToRgba = (hexColor, opacity = 1) => {
  const {
    r,
    g,
    b
  } = hexToRGB(hexColor);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const hoverStyle = css(["", ""], ({
  theme,
  use
}) => {
  if (use === TRANSPARENT_ON_BACKGROUND) {
    return `background-color: ${hexToRgba(theme.transparentOnBackgroundIconButton || theme.primary, 0.1)};`;
  }

  if (use === TRANSPARENT_ON_PRIMARY) {
    return `background-color: ${hexToRgba(theme.textOnPrimary, 0.1)};`;
  }

  return `background-color: ${adjustLuminance(theme.primary, 20)};`;
});
const activeStyle = css(["", ""], ({
  theme,
  use
}) => {
  if (use === TRANSPARENT_ON_BACKGROUND) {
    return `background-color: ${hexToRgba(theme.transparentOnBackgroundIconButton || theme.primary, 0.4)};`;
  }

  if (use === TRANSPARENT_ON_PRIMARY) {
    return `background-color: ${hexToRgba(theme.textOnPrimary, 0.4)};`;
  }

  return `background-color: ${adjustLuminance(theme.primary, -10)};`;
});
const disabledStyle = css(["", ""], ({
  theme,
  use
}) => `
    background-color: ${use === TRANSPARENT_ON_BACKGROUND || use === TRANSPARENT_ON_PRIMARY ? 'transparent' : theme.disabledBackground};
  `);
export const iconButtonTheme = {
  baseStyle: css(["flex-shrink:0;display:inline-flex;align-items:center;justify-content:center;border-radius:", ";width:", "px;height:", "px;vertical-align:middle;padding:0;text-align:center;text-overflow:clip;font-size:18px;line-height:18px;outline:none;transition:background-color 150ms ease-out;border:none;", " ", " > *{user-select:none;}", " ", " ", ""], ({
    shape
  }) => shape === CIRCLE ? '50%' : '3px', ({
    size
  }) => BUTTON_SIZES[size] || 40, ({
    size
  }) => BUTTON_SIZES[size] || 40, ({
    use,
    theme
  }) => use === TRANSPARENT_ON_BACKGROUND || use === TRANSPARENT_ON_PRIMARY ? `background-color: transparent;` : `background-color: ${theme.primary};`, ({
    use,
    theme
  }) => use === TRANSPARENT_ON_BACKGROUND ? `color: ${theme.transparentOnBackgroundIconButton || theme.primary};` : `color: ${theme.textOnPrimary};`, ({
    active
  }) => active ? activeStyle : '', ({
    disabled
  }) => disabled ? disabledStyle : '', ({
    hover
  }) => hover ? hoverStyle : ''),
  _disabled: css(["color:", ";cursor:not-allowed;"], ({
    theme
  }) => theme.disabledText),
  _focused: focusRing,
  _hovered: hoverStyle,
  _pressed: activeStyle
};