export const TO_KEY = 'to';
export const CC_KEY = 'cc';
export const BCC_KEY = 'bcc';
export const ALL_KEY = 'all';
export const SUBJECT = ['subject'];
export const TO = [TO_KEY];
export const CC = [CC_KEY];
export const BCC = [BCC_KEY];
export const FROM = ['from'];
export const FROM_EMAIL = ['from', 'email'];
export const FROM_NAME = ['from', 'name'];
export const CONNECTED_ACCOUNT_ADDRESS = ['connectedAccountAddress'];
export const HAS_REPLIES = ['hasReplies'];
export const PREVIOUS_REPLIES_HTML = ['previousRepliesHtml'];
export const PREVIOUS_REPLIES_PLAIN_TEXT = ['previousRepliesPlainText'];
export const ORIGINAL_SENDER_EMAIL = ['originalSender', 'email'];
export const ORIGINAL_SENDER_NAME = ['originalSender', 'name'];
export const MEMBER_OF_FORWARDED_SUBTHREAD = ['memberOfForwardedSubthread'];
export const IS_FORWARD = ['forward'];
export const CLIP_STATUS = ['clipStatus'];
export const TEMPLATE_ID = ['templateId'];
export const EMAIL_AUTHENTICATION_STATUS = ['emailAuthenticationStatus'];