import * as helpers from './common/helpers';
import * as messages from './common/messages';
export const createUnexpectedPropertiesLogger = warningLogger => {
  const alreadyWarnedCache = {};
  return (eventKey, unexpectedProperties, propertiesNotBeingDiscarded = true) => {
    if (unexpectedProperties.length) {
      const cacheKey = `${eventKey}:::${unexpectedProperties.join('-')}`; // Only warn once for an Event to be tracking things that are not defined in the schema for a specific track call
      // This means that `.track('some-event', propertiesObject)` (2x) will result in only one warn

      if (!alreadyWarnedCache[cacheKey]) {
        alreadyWarnedCache[cacheKey] = true;
        const warningReason = propertiesNotBeingDiscarded ? 'In the future unknown properties will be filtered out and not be tracked. Please add the missing properties to your events.yaml.' : 'All unexpected properties will not be tracked. Please add the missing properties to your events.yaml.';
        const unexpectedPropertiesToList = unexpectedProperties.join(', ');
        const warningMessage = messages.genericWarning(`Event ${eventKey} was tracked with unexpected properties [${unexpectedPropertiesToList}]. ${warningReason}`);
        warningLogger(warningMessage);
      }
    }
  };
};
export const createDebugLogger = logMessage => (eventKey, event) => {
  logMessage(messages.debugLog('event scheduled to be sent', {
    eventKey: `"${eventKey}"`,
    eventName: event.what_event,
    eventNamespace: event.where_app
  }));
  const prettifiedPayload = JSON.parse(JSON.stringify(event));
  prettifiedPayload.what_extra_json = JSON.parse(prettifiedPayload.what_extra_json);
  logMessage(prettifiedPayload);
};
export const createErrorLogger = (logError, onError) => (error, extra) => {
  helpers.ensureFn(onError)(error, extra);
  logError(error);
};
export const createWarningLogger = (logWarning, onWarning) => (warning, extra) => {
  helpers.ensureFn(onWarning)(warning, extra);
  logWarning(warning);
};
export const createPropertyResolutionFailedLogger = (logError, logWarning, onError) => (propertyName, error) => {
  helpers.ensureFn(onError)(error, {
    extra: {
      propertyName
    }
  });
  logError(error);
  logWarning(messages.genericWarning(`Property "${propertyName}" failed to resolve asynchronously and will fallback to "null".`));
};