export const AWAY_IN_OFFCE_HOURS_STRATEGY = ['awayInOfficeHoursStrategy'];
export const AWAY_MESSAGE = ['awayMessage'];
export const OFFICE_HOURS = ['officeHours'];
export const OUTSIDE_OFFICE_HOURS_MESSAGE = ['outsideOfficeHoursMessage'];
export const OUTSIDE_OFFICE_HOURS_STRATEGY = ['outsideOfficeHoursStrategy'];
export const TEAM_MEMBERS_AVAILABILITY_STRATEGY = ['teamMembersAvailabilityStrategy'];
export const TYPICAL_RESPONSE_TIME = ['typicalResponseTime'];
export const AVAILABILITY_STRATEGY = ['availabilityStrategy'];
export const AVAILABILITY_STRATEGY_CONFIG = ['availabilityStrategyConfig'];
export const AVAILABILITY_STRATEGY_CONFIG_AWAY_IN_OFFICE_HOURS_STRATEGY = ['availabilityStrategyConfig', 'awayInOfficeHoursStrategy'];
export const AVAILABILITY_STRATEGY_CONFIG_AWAY_MESSAGE = ['availabilityStrategyConfig', 'awayMessage'];
export const AVAILABILITY_STRATEGY_CONFIG_OUTSIDE_OFFICE_HOURS_MESSAGE = ['availabilityStrategyConfig', 'outsideOfficeHoursMessage'];
export const AVAILABILITY_STRATEGY_CONFIG_OUTSIDE_OFFICE_HOURS_STRATEGY = ['availabilityStrategyConfig', 'outsideOfficeHoursStrategy'];
export const AVAILABILITY_STRATEGY_CONFIG_TEAM_MEMBERS_AVAILABILITY_STRATEGY = ['availabilityStrategyConfig', 'teamMembersAvailabilityStrategy'];
export const AVAILABILITY_STRATEGY_CONFIG_TYPICAL_RESPONSE_TIME = ['availabilityStrategyConfig', 'typicalResponseTime'];