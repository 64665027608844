module.exports = {
  "/": {
    "success": {
      "default": [
        "VISITOR_SUCCESS"
      ]
    },
    "error": [
      "VISITOR_ERROR"
    ]
  }
};